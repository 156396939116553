























































































































































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Sidebar from '@/components/left-sidebar.vue'; // @ is an alias to /src
import RSidebar from '@/components/right-sidebar.vue'; // @ is an alias to /src
import ProjectService from "../../../services/project-service";
import vendorService from '@/services/vendor-service';

@Component({
  components: {
    Header,
    Sidebar,
    RSidebar
  },
})
export default class Employee extends Vue {
  public filter = null;
  private title: string = "";
  public create_obj: any = {}

  public fields = [
    {
      key: 'code',
      sortable: false
    },
    {
      key: 'title',
      sortable: false
    },
    {
      key: 'owner',
      sortable: false
    },
    {
      key: 'start_date',
      sortable: false,

    },
    {
      key: 'end_date',
      sortable: false,
    },
    {
      key: 'retention_per',
      sortable: false,

    },
    {
      key: 'retention_days',
      sortable: false,

    },
    {
      key: 'amount',
      Label: 'Project Cost',
      sortable: false,

    },
  ];
  public columns = [
    {
      label: 'code',
      field: 'code',
    },
    {
      label: 'title',
      field: 'title',
    },
    {
      label: 'customer',
      field: 'owner',
    },
    {
      label: 'retention_per',
      field: 'retention_per',
    },
    {
      label: 'retention_days',
      field: 'retention_days',
    },
    {
      label: 'amount',
      field: 'amount',
    },
    {
      label: 'start_date',
      field: 'start_date',
    },
    {
      label: 'end_date',
      field: 'end_date',
    },
    {
      label: 'type',
      field: 'node_type',
    }
  ];

  public items = [];
  public ddl_items: any = []
  public import_response = '';
  public import_file: any = null;
  public options_vendor: any = [];
  public selected_items = [];  
  public currentPage = 1;
  get rows() {
    return this.selected_items.length;
  }

  retrieve() {
    ProjectService.get_list()
      .then((response) => {
        this.items = response.data;
        this.selected_items = response.data;
        this.ddl_items = response.data;        
      })
      .catch((e) => {
        console.log(e);
      });
    vendorService.get_list()
      .then((response) => {
        this.options_vendor = response.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  refreshList() {
    this.retrieve();
  }

  submit_import() {
  }

  create() {
    let temp = this.create_obj['vendor'];
    this.create_obj['user'] = 2;
    this.create_obj['vendor'] = temp['id']
    this.create_obj['owner'] = temp['title']
    this.create_obj['node_type'] = 'Node'
    ProjectService.create_list(this.create_obj).then((response) => {
      this.create_obj = {};
      alert('Successfully Created')
      this.retrieve()
    }).catch((e) => {
    })
  }

  searchTitle() {
    ProjectService.getByTitle(this.title)
      .then((response) => {
        this.items = response.data;
        this.selected_items = response.data        
      })
      .catch((e) => {
        console.log(e);
      });
  }


  onRowClicked(item: any, index: any, event: any) {    
    this.$router.push({ name: "project-create-list", params: { id: item.id.toString() } });
  }

  mounted() {
    this.retrieve();
  }
}
